<template>
  <v-sheet min-height="300" class="d-flex flex-column">
    <section v-if="!loading">
      <div class="">
        <breadcrumbs
          :items="[
            {
              text: '<  My Rubrics',
              disabled: false,
              link: true, 
              to: (prevRoute && prevRoute.name === 'Instructor Assessment Rubrics') ? { name: 'Instructor Assessment Rubrics' , query: { ...prevRoute.query}} : { name: 'Instructor Assessment Rubrics' , query: { tab: 0 }}
            },
            {
              text: rubric.title,
              disabled: true,
              to: null,
            },
          ]"
        />
      </div>
      <v-divider />
      <div class="pa-5">
        <RubricForm :rubric="rubric" action="update" :type="rubric.type_of_rubric" />
      </div>
    </section>
    <circular v-else  class="ma-auto"/>
  </v-sheet>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RubricForm from "../../components/teacher/rubrics/RubricForm.vue";
export default {
  components: {
    RubricForm,
  },
  data: () => ({
    loading: true,
    prevRoute: null
  }),
  computed: {
    ...mapState("instructor", {
      rubric: (state) => state.rubrics,
    }),
  },
  created() {
    this.showRubricAction(this.$route.params.id).then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions("instructor", ["showRubricAction", "getRubricsAction"]),
  },

  beforeRouteLeave(to, from, next) {
    this.getRubricsAction().then(() => {
      next();
    });
  },

  beforeRouteEnter(to, from, next) {
      next(vm => {
          vm.prevRoute = from          
      })
  },
};
</script>

<style></style>
