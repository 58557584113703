var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"d-flex flex-column",attrs:{"min-height":"300"}},[(!_vm.loading)?_c('section',[_c('div',{},[_c('breadcrumbs',{attrs:{"items":[
          {
            text: '<  My Rubrics',
            disabled: false,
            link: true, 
            to: (_vm.prevRoute && _vm.prevRoute.name === 'Instructor Assessment Rubrics') ? { name: 'Instructor Assessment Rubrics' , query: { ..._vm.prevRoute.query}} : { name: 'Instructor Assessment Rubrics' , query: { tab: 0 }}
          },
          {
            text: _vm.rubric.title,
            disabled: true,
            to: null,
          },
        ]}})],1),_c('v-divider'),_c('div',{staticClass:"pa-5"},[_c('RubricForm',{attrs:{"rubric":_vm.rubric,"action":"update","type":_vm.rubric.type_of_rubric}})],1)],1):_c('circular',{staticClass:"ma-auto"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }